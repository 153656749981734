import { getPageUrl } from "../../utils/common";
import Iframe from "../components/Iframe";

function MaxAdmin(props) {
  const appName = "max/admin";
  const state = props?.location?.state || {};
  const pathName = state?.url ? `/${state?.url}` : undefined;
  const pathParams = state?.params;
  const pageUrl = getPageUrl(appName, state);

  return (
    <Iframe
      appName={appName}
      pageUrl={pageUrl}
      pathName={pathName}
      pathParams={pathParams}
    />
  );
}

export default MaxAdmin;
