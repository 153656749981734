import React, { useEffect, useState } from "react";
import { HomeOutlined } from "@ant-design/icons";
import { Menu, message } from "antd";
import { Switch } from "react-router-dom";
import { getUserInfo, requestPermission } from "../../utils/common";
import Formular from "../formular";
import BI from "../bi";
import Max from "../max";
import MaxAdmin from "../max-admin";
import BIAdmin from "../bi-admin";
import UserCenter from "../user-center";
import AuthRoute from "../components/AuthRoute";
import Icon from "../../assest/images/icon.png";
import Icon1 from "../../assest/images/icon1.png";
import Icon2 from "../../assest/images/icon2.png";
import Icon3 from "../../assest/images/icon3.png";
import Icon4 from "../../assest/images/icon4.png";
import "./index.scss";

function Main(props) {
  const getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label,
      popupClassName: "pro-submenu",
    };
  };
  const items = [
    getItem("数据处理", "formular", <img src={Icon} />),
    getItem("数据分析", "bi", <img src={Icon1} />),
    getItem("数据可视化", "max", <img src={Icon2} />),
    getItem("系统管理", "system", <img src={Icon3} />, [
      getItem("用户中心", "oauth/admin"),
      getItem("分析系统管理后台", "bi/admin"),
      getItem("可视化系统管理后台", "max/admin"),
    ]),
  ];
  const [menuList, setMenuList] = useState([]);
  const [seletedMenuKey, setSelectedMenuKey] = useState("formular");
  const [userInfo, setUserInfo] = useState({});
  const { permit_app = [] } = userInfo;
  const handleMenuClick = ({ key }) => {
    setSelectedMenuKey(key);
    if (key == "home") {
      props.history.push(`/${key}`);
    } else if (key == "bi") {
      props.history.push({
        pathname: `/main/${key}`,
        state: {
          url: "dashboard",
        },
      });
    } else if (key == "oauth/admin") {
      props.history.push({
        pathname: `/main/${key}`,
        state: {
          url: "admin/home",
        },
      });
    } else {
      props.history.push(`/main/${key}`);
    }
  };

  useEffect(() => {
    requestPermission(
      () => {
        setUserInfo(getUserInfo());
      },
      (err) => {
        message.error(err.message);
      }
    );
  }, []);

  useEffect(() => {
    const apps = permit_app.map((key) => {
      let newKey = key;
      switch (key) {
        case "formula":
          newKey = "formular";
          break;
        case "oauth":
          newKey = "oauth/admin";
          break;
        case "bi_admin":
          newKey = "bi/admin";
          break;
        case "max_admin":
          newKey = "max/admin";
          break;
      }
      return newKey;
    });

    const list = [];
    items.forEach((item) => {
      let flag = false;
      if (!item.children) {
        flag = apps.find((key) => key == item.key);
        if (flag) {
          list.push(item);
        }
      } else {
        flag = item.children.find((childItem) =>
          apps.find((key) => key == childItem.key)
        );
        if (flag) {
          item.children = item.children.filter((childItem) => {
            return apps.find((key) => key == childItem.key);
          });
          list.push(item);
        }
      }
    });
    list.push(getItem("首页", "home", <img src={Icon4} />));
    setMenuList(list);
    const pathArr = props.location.pathname.split("/");
    setSelectedMenuKey(pathArr[pathArr.length - 1]);
  }, [permit_app]);

  return (
    <div className="main-portal">
      <div className="nav">
        <Menu
          key={menuList.length}
          onClick={handleMenuClick}
          selectedKeys={[seletedMenuKey]}
          mode="inline"
          theme="light"
          inlineCollapsed={true}
          items={menuList}
        />
      </div>
      <div className="content">
        <Switch>
          <AuthRoute
            path="/main/formular"
            component={Formular}
            exact
          ></AuthRoute>
          <AuthRoute path="/main/bi" component={BI} exact></AuthRoute>
          <AuthRoute path="/main/max" component={Max} exact></AuthRoute>
          <AuthRoute
            path="/main/max/admin"
            component={MaxAdmin}
            exact
          ></AuthRoute>
          <AuthRoute
            path="/main/bi/admin"
            component={BIAdmin}
            exact
          ></AuthRoute>
          <AuthRoute
            path="/main/oauth/admin"
            component={UserCenter}
            exact
          ></AuthRoute>
        </Switch>
      </div>
    </div>
  );
}

export default Main;
