// import MicroApp from "../components/MicroApp";
import { getPageUrl } from "../../utils/common";
import Iframe from "../components/Iframe";

function BI(props) {
  const appName = "bi";
  const state = props?.location?.state || {};
  const pathName = state?.url ? `/${state?.url}` : "/dashboard";
  const pathParams = state?.params;
  const pageUrl = getPageUrl(appName, {url: 'account/sso'});
  return (
    <Iframe
      appName={appName}
      pageUrl={pageUrl}
      pathName={pathName}
      pathParams={pathParams}
    />
  );
}

export default BI;
