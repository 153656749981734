import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthRoute from "./pages/components/AuthRoute";
import Home from "./pages/home";
import Main from "./pages/main";
import Sso from "./pages/sso";
import { Helmet } from "react-helmet";
import "./App.scss";

function App() {
  const { systemTitle, systemDesc } = window.__PORTAL_CONFIG;
  return (
    <div className="App">
      <Helmet>
        <meta name="description" content={systemDesc} />
        <title>{systemTitle}</title>
      </Helmet>
      <Switch>
        <AuthRoute path="/home" component={Home} exact></AuthRoute>
        <AuthRoute path="/main" component={Main}></AuthRoute>
        <Route path="/sso" component={Sso} exact></Route>
        <Redirect from="/" to="/home" exact />
      </Switch>
    </div>
  );
}

export default App;
