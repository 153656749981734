import { getCookie } from "../../utils/common";

function Iframe(props) {
  const { appName, pageUrl, pathName, pathParams } = props;
  const loginInfo = {
    appName: appName,
    access_token: getCookie("access_token"),
    login_type: "sso",
    pathName: pathName,
    pathParams: JSON.stringify(pathParams),
  };
  window.__LOGIN_INFO = loginInfo
  const onLoad = () => {
    const frame = document.getElementById(`${appName}`);
    const contentWindow = frame.contentWindow;
    contentWindow.postMessage({ loginInfo, type: "portal" }, "*");
  };
  return (
    <iframe
      onLoad={onLoad}
      id={appName}
      name={appName}
      src={pageUrl}
      height="100%"
      frameBorder="0"
      scrolling="no"
    ></iframe>
  );
}

export default Iframe;
