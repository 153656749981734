import { getPageUrl } from "../../utils/common";
import Iframe from "../components/Iframe";

function Formular(props) {
  const appName = "formular";
  let state = props?.location?.state || {};
  let pathName = sessionStorage.getItem(`${appName}$pathName`);
  if(!pathName) {
    pathName = state?.url ? `/${state?.url}` : undefined;
  }
  const pathParams = state?.params || {};

  const pageUrl = getPageUrl(appName, {url: 'sso'});
  return (
    <Iframe
      appName={appName}
      pageUrl={pageUrl}
      pathName={pathName}
      pathParams={pathParams}
    />
  );
}

export default Formular;
