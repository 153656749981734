import axios from "axios";
import { getCookie } from "./common";

axios.defaults.baseURL = `${
  process.env.NODE_ENV == "development" ? "" : window._PORTAL_PREFIX_}`;
//post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
axios.defaults.timeout = 30000;

axios.interceptors.request.use(
  (config) => {
    const token = getCookie("access_token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      if (response.data.code == 0) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log(error);
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  post(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${url}?t=${Date.now()}`,
        params: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
