import React, { Component } from "react";
import { message } from "antd";
import Axios from "axios";
import request from "../../utils/request";
import CircleBg from "./circlebg";
import "./index.scss";
import { getCookie, requestPermission } from "../../utils/common";
export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      dataList: [],
    };
  }
  componentDidMount() {
    if (!getCookie("access_token")) {
      request.get("/chameleon/oauth2/login").then((res) => {
        if (res.login_list.length === 1) {
          let url = window.location.pathname;
          console.log("res000000", res);
          window.location.href = `/portal/${res.login_list[0].path}?url=${url}`;
        } else {
          this.setState({
            dataList: res.login_list,
          });
        }
      });
      return;
    }
    requestPermission(
      () => {
        this.props.history.push("/home");
      },
      () => {
        message.error("登录失败");
      }
    );
  }
  goToLoginUrl = (item) => {
    let pathname = window.location.pathname;
    let urlStr = `${item.path}?url=${pathname}`;
    window.location.href = urlStr;
  };
  render() {
    const { dataList } = this.state;
    return (
      <div className="user-login">
        <div className="home-content">
          {dataList &&
            dataList.length > 1 &&
            dataList.map((item, ind) => {
              return (
                <div key={ind} className="home-item">
                  <div className="home-entrance">
                    <div className="title">{item.platform}</div>
                    <button
                      onClick={() => {
                        this.goToLoginUrl(item);
                      }}
                      className="home-btn"
                    >
                      登录
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <CircleBg />
      </div>
    );
  }
}
