import React from 'react';
import { isBadBrowser } from '../../utils/common';

export default class CircleBg extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: []
    }
  }
  componentDidMount() {
    this.init(15);
  }
  //初始化元素信息
  init = (num) => {
    this.clientWidth = document.documentElement.clientWidth;
    this.clientHeight = document.documentElement.clientHeight;
    const dataSource = [];
    this.rArr = [];
    for (let i = 0; i < num; i++) {
      dataSource.push({
        x: Math.random() * this.clientWidth,
        y: Math.random() * this.clientHeight,
        vx: 0,
        vy: 0
      })
      this.rArr.push(Math.floor(Math.random() * 200) + 80)
    }
    if (isBadBrowser()) {
      return
    }
    this.setState({ dataSource }, () => {
      this.interval = setInterval(() => {
        this.run()
      }, 50)
    })
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  run = () => {
    const dataSource = this.state.dataSource.map((d) => {
      let vx = d.vx + Math.random() * 0.6 - 0.3;
      let vy = d.vy + Math.random() * 0.6 - 0.3;
      if (vx >= 1.8) {
        vx = 1.2;
      } else if (vx <= -1.8) {
        vx = -1.2;
      }
      if (vy >= 1.8) {
        vy = 1.2;
      } else if (vy <= -1.8) {
        vy = -1.2;
      }
      let x = d.x + vx;
      let y = d.y + vy;
      if (x >= this.clientWidth) {
        vx = - Math.abs(vx);
      }
      if (x <= 0) {
        vx = Math.abs(vx);
      }
      if (y >= this.clientHeight) {
        vy = -Math.abs(vy);
      }
      if (y <= 0) {
        vy = Math.abs(vy);
      }
      return {
        vx, vy, x, y
      }
    })
    this.setState({ dataSource })
  }
  render() {
    const { dataSource } = this.state;

    return (
      <div className='bg'>
        {
          this.rArr && dataSource.map((d, i) => {
            return <div key={i} className={`circle${i}`} style={{ width: this.rArr[i], height: this.rArr[i], transform: `translate(${d.x}px,${d.y}px)` }}></div>
          })
        }
      </div>
    )
  }
}
