import { getPageUrl } from "../../utils/common";
import Iframe from "../components/Iframe";

function Max(props) {
  const appName = "max";
  const state = props?.location?.state || {};
  const pathName = state?.url ? `/${state?.url}` : "/screenList";
  const pathParams = state?.params;
  const pageUrl = getPageUrl(appName, { url: "sso" });

  return (
    <Iframe
      appName={appName}
      pageUrl={pageUrl}
      pathName={pathName}
      pathParams={pathParams}
    />
  );
}

export default Max;
