
import Shujuji from "../../assest/images/shujuji.png"
import Etl from "../../assest/images/etl.png"
import API from "../../assest/images/api.png"
import Kanban from "../../assest/images/kanban.png"
import Gushiban from "../../assest/images/gushiban.png"
import Keshihua from "../../assest/images/keshihua.png"


export const navImgs = {
    'a' : Shujuji,
    'b' : Etl,
    'c' : Kanban,
    'd' : Gushiban,
    'f' : Keshihua,
    'g' : API,
}