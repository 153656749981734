export const menus = [
  {
    name: "bi",
    list: [
      {
        title: "数据分析",
        imgKey: "bi",
        children: [
          {
            title: "看板管理",
            path: "dashboard",
          },
        ],
      },
      {
        title: "数据报告",
        imgKey: "report",
        children: [
          {
            title: "故事板管理",
            path: "storyboard",
          },
        ],
      },
    ],
  },
  {
    name: "max",
    list: [
      {
        title: "数据可视化",
        imgKey: "max",
        children: [
          {
            title: "新建数据可视化",
            path: "createScreen",
            params: {
              groupId: 0,
            },
          },
          {
            title: "可视化管理",
            path: "screenList",
            params: {
              groupId: 0,
            },
          },
        ],
      },
    ],
  },
  {
    name: "formula",
    list: [
      {
        title: "数据汇聚",
        imgKey: "huiju",
        children: [
          {
            title: "抽取任务管理",
            path: "manage/extract/datasource",
            key: "data-extract",
          },
          {
            title: "订阅任务管理",
            path: "manage/extract/dataPush",
            key: "data-push",
          },
          {
            title: "数据源管理",
            path: "manage/extract/connected",
            key: "data-extract.data-source",
          },
        ],
      },
      {
        title: "数据开发",
        imgKey: "dev",
        children: [
          {
            title: "数据ETL清洗管理",
            path: "manage/process/process_tasks",
            key: "data-process.task-list",
          },
          {
            title: "算法开发管理",
            path: "manage/data_assets/algorithm",
            key: "data-process.algorithm-list",
          },
          {
            title: "标签开发管理",
            path: "manage/process/label_management",
            key: "data-process.tag-list",
          },
        ],
      },
      {
        title: "数据共享",
        imgKey: "share",
        children: [
          {
            title: "API管理",
            path: "manage/service/api",
            key: "data-service.api",
          },
          {
            title: "数据库推送管理",
            path: "manage/service/dbAsync",
            key: "database-sync",
          },
        ],
      },
    ],
  },
];

export const quickMenus = [
  {
    name: "formula",
    list: [
      {
        key: "data-extract",
        title: "抽取新数据集",
        path: "extract/select",
        params: {
          taskGroupId: 0,
        },
        imgKey: "a",
      },
      {
        key: "data-process.task-list",
        title: "新建数据ETL清洗",
        path: "process/data_transform",
        params: {
          taskGroupId: 0,
        },
        imgKey: "b",
      },
      {
        key: "data-service.api",
        title: "新建API",
        path: "service/apiCreate",
        params: {
          taskGroupId: 0,
        },
        imgKey: "g",
      },
    ],
  },
  {
    name: "bi",
    list: [
      {
        title: "看板管理",
        path: "dashboard",
        imgKey: "c",
      },
      {
        title: "故事板管理",
        path: "storyboard",
        imgKey: "d",
      },
    ],
  },
  {
    name: "max",
    list: [
      {
        title: "新建数据可视化",
        path: "createScreen",
        params: {
          groupId: 0,
        },
        imgKey: "f",
      },
    ],
  },
];
