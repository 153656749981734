import request from "./request";

const getPageUrl = (appName, state) => {
  const { hostMap } = window.__PORTAL_CONFIG;
  const baseUrl = hostMap[appName];
  const { url } = state || {};
  let pageUrl = baseUrl;
  if (url) {
    pageUrl = baseUrl + url;
  }
  return pageUrl;
};
const getBaseUrl = (appName) => {
  const { hostMap } = window.__PORTAL_CONFIG;
  const baseUrl = hostMap[appName];
  return baseUrl;
};
const isBadBrowser = () => {
  const reg = /trident\/(\d)/i;
  if (reg.test(navigator.userAgent)) {
    const version = Number(RegExp.$1);
    //trident 内核版本低于 6 表示 IE 版本低于 10
    return version < 6;
  }
  if (navigator.userAgent.indexOf("Edge") !== -1) {
    return true;
  }
};

const getCookie = (c_name) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return decodeURI(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
};

export function delCookie(c_name) {
  const oldDate = new Date(0).toGMTString();
  document.cookie = `${c_name}=; expires=${oldDate}; path=/`;
}

export function clearCookie(retainCookies = []) {
  const keys = document.cookie.match(/[^=;]+(?=\=)/g);
  if (keys) {
    for (let i = keys.length; i--; ) {
      const key = keys[i];
      if (!retainCookies.includes(key)) {
        delCookie(key);
      }
    }
  }
}

const getUserInfo = () => {
  let userInfo = {};
  if (localStorage.portal_user) {
    userInfo = JSON.parse(localStorage.portal_user).open_user_info;
  }
  return userInfo;
};

const getMenuCodes = () => {
  let menuCodes = [];
  if (localStorage.menu_codes) {
    menuCodes = JSON.parse(localStorage.menu_codes);
  }
  return menuCodes;
};

const requestPermission = (sucessFn, errorFn) => {
  request
    .post("/chameleon/portal/permission")
    .then((res) => {
      localStorage.setItem("portal_user", JSON.stringify(res.user));
      localStorage.setItem("menu_codes", JSON.stringify(res.menu_codes || []));
      localStorage.setItem("portal_auth", getCookie("access_token"));
      sucessFn && sucessFn();
    })
    .catch((err) => {
      errorFn && errorFn(err);
    });
};

export {
  getPageUrl,
  isBadBrowser,
  getCookie,
  getUserInfo,
  getMenuCodes,
  getBaseUrl,
  requestPermission,
};
