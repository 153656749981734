import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";

function render() {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <BrowserRouter basename={`${window._PORTAL_PREFIX_}`}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  );
}

function start() {
  const defaultConfig = `${window._PORTAL_PREFIX_}/serverConfig.json?t=${new Date().getTime()}`;
  fetch(defaultConfig)
    .then((response) => response.json())
    .then((jsonResponse) => {
      window.__PORTAL_CONFIG = jsonResponse;
      render();
    });
}
start();
