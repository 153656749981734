import React, { useState } from "react";
import BiImg from "../../assest/platform/bi.png";
import BiIcon from "../../assest/platform/bi-icon.png";
import BiActiveIcon from "../../assest/platform/bi-active-icon.png";
import MaxImg from "../../assest/platform/max.png";
import MaxIcon from "../../assest/platform/max-icon.png";
import MaxActiveIcon from "../../assest/platform/max-active-icon.png";
import FormularImg from "../../assest/platform/formular.png";
import FormularIcon from "../../assest/platform/formular-icon.png";
import FormularActiveIcon from "../../assest/platform/formular-active-icon.png";

function Platform(props) {
  const { appKeys } = props;
  const { platform } = window.__PORTAL_CONFIG;
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const onMouseOver = (index) => {
    setIsActive(true);
    setActiveIndex(index);
  };

  const onMouseOut = () => {
    setIsActive(false);
    setActiveIndex(-1);
  };

  return (
    <>
      <div className="platform">
        {appKeys.includes("bi") && (
          <div
            className="product"
            onMouseOver={() => onMouseOver(0)}
            onMouseOut={onMouseOut}
          >
            <div
              className={`top ${
                isActive && activeIndex == 0 ? "top-choose" : ""
              }`}
            >
              <img
                src={BiIcon}
                className={isActive && activeIndex == 0 ? "display-none" : ""}
              />
              <img
                src={BiActiveIcon}
                className={
                  !(isActive && activeIndex == 0) ? "display-none" : ""
                }
              />
              <div
                className={`title ${
                  isActive && activeIndex == 0 ? "text-choose" : ""
                }`}
              >
                Data Analytics
              </div>
              <div
                className={`sub-title ${
                  isActive && activeIndex == 0 ? "text-choose" : ""
                }`}
              >
                业务驱动型BI工具
              </div>
            </div>
            <div
              className={`img ${
                isActive && activeIndex == 0 ? "display-none" : ""
              }`}
            >
              <div className="bottom">
                <img src={BiImg} className="bottom-img" />
                <a href={platform.bi} target="_blank">
                  <div className="button">进入平台</div>
                </a>
              </div>
            </div>
            <div
              className={`txt ${
                !(isActive && activeIndex == 0) ? "display-none" : ""
              }`}
            >
              <div
                className={`bottom ${
                  isActive && activeIndex == 0 ? "bottom-choose" : ""
                }`}
              >
                <div className="title flexW">
                  <div className="header"></div>
                  <div className="text">适用人群</div>
                </div>
                <div className="bottom-text">
                  管理者，销售人员，财务人员，人力资源部，数据分析师等有数据分析需求的业务人员
                </div>
                <div className="title sub-title flexW">
                  <div className="header"></div>
                  <div className="text">使用场景</div>
                </div>
                <div className="bottom-text">
                  领导驾驶舱、用户分析、销售管理、财务分析、人力资源管控等
                </div>
                <div className="title sub-title flexW">
                  <div className="header"></div>
                  <div className="text">产品优点</div>
                </div>
                <div className="bottom-text">
                  操作简单，非专业人士也能立即上手；
                  <br />
                  探索式分析，快速发现问题并改进业务；
                  <br />
                  支持团队讨论和报告故事板，提高内部效率。
                </div>
                <a href={platform.bi} target="_blank">
                  <div className="button">进入平台</div>
                </a>
              </div>
            </div>
          </div>
        )}
        {appKeys.includes("max") && (
          <div
            className="product"
            onMouseOver={() => onMouseOver(1)}
            onMouseOut={onMouseOut}
          >
            <div
              className={`top ${
                isActive && activeIndex == 1 ? "top-choose" : ""
              }`}
            >
              <img
                src={MaxIcon}
                className={isActive && activeIndex == 1 ? "display-none" : ""}
              />
              <img
                src={MaxActiveIcon}
                className={
                  !(isActive && activeIndex == 1) ? "display-none" : ""
                }
              />
              <div
                className={`title ${
                  isActive && activeIndex == 1 ? "text-choose" : ""
                }`}
              >
                Data MAX
              </div>
              <div
                className={`sub-title ${
                  isActive && activeIndex == 1 ? "text-choose" : ""
                }`}
              >
                极致性价比的大屏可视化产品
              </div>
            </div>
            <div
              className={`img ${
                isActive && activeIndex == 1 ? "display-none" : ""
              }`}
            >
              <div className="bottom">
                <img src={MaxImg} className="bottom-img" />
                <a href={platform.max} target="_blank">
                  <div className="button">进入平台</div>
                </a>
              </div>
            </div>
            <div
              className={`txt ${
                !(isActive && activeIndex == 1) ? "display-none" : ""
              }`}
            >
              <div
                className={`bottom ${
                  isActive && activeIndex == 1 ? "bottom-choose" : ""
                }`}
              >
                <div className="title flexW">
                  <div className="header"></div>
                  <div className="text">适用人群</div>
                </div>
                <div className="bottom-text">
                  有数据可视化展示需求的政府机构和企业
                </div>
                <div className="title sub-title flexW">
                  <div className="header"></div>
                  <div className="text">使用场景</div>
                </div>
                <div className="bottom-text">
                  汇报和展览、指挥中心、业务看板、工业监控、园区管理、智慧城市等
                </div>
                <div className="title sub-title flexW">
                  <div className="header"></div>
                  <div className="text">产品优点</div>
                </div>
                <div className="bottom-text">
                  操作简单，拖拉拽即可生成酷炫大屏；
                  <br />
                  海量主题上百组件，还支持专属定制；
                  <br />
                  统一后台管理，让团队使用更加方便。
                </div>
                <a href={platform.max} target="_blank">
                  <div className="button">进入平台</div>
                </a>
              </div>
            </div>
          </div>
        )}
        {appKeys.includes("formula") && (
          <div
            className="product"
            onMouseOver={() => onMouseOver(2)}
            onMouseOut={onMouseOut}
          >
            <div
              className={`top ${
                isActive && activeIndex == 2 ? "top-choose" : ""
              }`}
            >
              <img
                src={FormularIcon}
                className={isActive && activeIndex == 2 ? "display-none" : ""}
              />
              <img
                src={FormularActiveIcon}
                className={
                  !(isActive && activeIndex == 2) ? "display-none" : ""
                }
              />
              <div
                className={`title ${
                  isActive && activeIndex == 2 ? "text-choose" : ""
                }`}
              >
                Data Formula
              </div>
              <div
                className={`sub-title ${
                  isActive && activeIndex == 2 ? "text-choose" : ""
                }`}
              >
                敏捷型数据中台
              </div>
            </div>
            <div
              className={`img ${
                isActive && activeIndex == 2 ? "display-none" : ""
              }`}
            >
              <div className="bottom">
                <img src={FormularImg} className="bottom-img" />
                <a href={platform.formular} target="_blank">
                  <div className="button">进入平台</div>
                </a>
              </div>
            </div>
            <div
              className={`txt ${
                !(isActive && activeIndex == 2) ? "display-none" : ""
              }`}
            >
              <div
                className={`bottom ${
                  isActive && activeIndex == 2 ? "bottom-choose" : ""
                }`}
              >
                <div className="title flexW">
                  <div className="header"></div>
                  <div className="text">适用人群</div>
                </div>
                <div className="bottom-text">
                  要解决数据孤岛、数据不一致、维护混乱等数据治理以及数据资产管理需求的企业
                </div>
                <div className="title sub-title flexW">
                  <div className="header"></div>
                  <div className="text">使用场景</div>
                </div>
                <div className="bottom-text">
                  数据集成、数据资产运营、数据质量管理、数据模型搭建、标签管理、画像管理等
                </div>
                <div className="title sub-title flexW">
                  <div className="header"></div>
                  <div className="text">产品优点</div>
                </div>
                <div className="bottom-text">
                  部署简单可迅速落地实践；
                  <br />
                  完善的数据治理方法论；
                  <br />
                  可视化操作界面，方便快捷。
                </div>
                <a href={platform.formular} target="_blank">
                  <div className="button">进入平台</div>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="platform-admin">
        {appKeys.includes("bi_admin") && (
          <div className="button-box">
            <a href={platform.bi_admin} target="_blank">
              <div className="button">分析系统管理后台</div>
            </a>
          </div>
        )}
        {appKeys.includes("max_admin") && (
          <div className="button-box">
            <a href={platform.max_admin} target="_blank">
              <div className="button">可视化系统管理后台</div>
            </a>
          </div>
        )}
        {appKeys.includes("oauth") && (
          <div className="button-box">
            <a href={platform.oauth} target="_blank">
              <div className="button">用户中心</div>
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default Platform;
