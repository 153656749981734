import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { Dropdown, Menu, message } from "antd";
import defaultAvatar from "../../assest/images/user.png";
import {
  getUserInfo,
  getMenuCodes,
  getCookie,
  clearCookie,
  requestPermission,
} from "../../utils/common";
import request from "../../utils/request";
import { menus, quickMenus } from "../../utils/menus";
import { navImgs } from "./config";
import Platform from "./Platform";
import "./index.scss";

function Home(props) {
  const [userInfo, setUserInfo] = useImmer({
    avatar: "",
    name: "",
    biMenus: [],
    maxMenus: [],
    formulaMenus: [],
    topMenus: [],
  });
  const token = getCookie("access_token");
  const menuCodes = getMenuCodes();
  const { permit_app: appKeys = [] } = getUserInfo();
  const isSuper = menuCodes.includes("super");
  const { logoConf, showPlatform } = window.__PORTAL_CONFIG;
  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    clearCookie();
  };
  const menu = (
    <Menu
      className="header-menu"
      items={[
        {
          key: "name",
          title: userInfo.name.length > 10 ? userInfo.name : "",
          label: <>{userInfo.name}</>,
        },
        {
          key: "logout",
          label: (
            <>
              <a
                onClick={logOut}
                href={`/portal/chameleon/oauth2/logout?token=${token}&redirect=/portal/sso`}
              >
                退出登录
              </a>
            </>
          ),
        },
      ]}
    />
  );

  const goToPage = (item, appName) => {
    const app = appName ? appName : item.appName;
    const pageUrl = item.path;
    if(app == 'max' && pageUrl == 'admin') {
      props.history.push({
        pathname: `/main/max/admin`,
      });
    } else {
      sessionStorage.setItem(`${app}$pathName`, `/${pageUrl}`);
      props.history.push({
        pathname: `/main/${app}`,
        state: {
          url: pageUrl,
          params: item.params,
        },
      });
    }
  };

  const getMenusBykey = (key, permitApps) => {
    let newMenus =
      menus.find((item) => permitApps.includes(key) && item.name == key)
        ?.list || [];
    newMenus = JSON.parse(JSON.stringify(newMenus));
    if (key == "formula" && !isSuper) {
      newMenus.forEach((item) => {
        item.children = item.children.filter((childItem) =>
          menuCodes.includes(childItem.key)
        );
      });
    }
    if(key == 'max' && permitApps.includes('max_admin')) {
      newMenus.forEach(item => {
        item.children.splice(1, 0, {
          title: '可视化系统管理后台',
          path: 'admin'
        });
      });
    }
    return newMenus;
  };

  const getAppName = (key) => {
    if (key == "formula") {
      return "formular";
    }
    return key;
  };

  const getTopMenus = (keys, permitApp) => {
    let topMenus = [];
    keys.forEach((key) => {
      let arr =
        quickMenus.find((item) => permitApp.includes(key) && item.name == key)
          ?.list || [];
      if (key == "formula" && !isSuper) {
        arr = arr.filter((item) => menuCodes.includes(item.key));
      }
      arr = arr.map((item) => {
        return {
          ...item,
          appName: getAppName(key),
        };
      });
      topMenus.push(...arr);
    });
    return topMenus;
  };

  const renderMenus = (key) => {
    const appName = getAppName(key);
    return (userInfo[`${key}Menus`] || []).map((item, index) => {
      return (
        <div className="menu-box" key={index}>
          <div className="left-bg"></div>
          <span
            title={item.hoverTitle}
            className={`menu ${
              ["bi", "max", "report"].includes(item.imgKey) ? "right-color" : ""
            }`}
          >
            {item.title}
          </span>
          <div className={`sub-menu-box ${key == "max" && item.children.length == 2 ? "one-line" : ""}`}>
            {item.children.map((childItem, childIndex) => {
              return (
                <div
                  onClick={() => goToPage(childItem, appName)}
                  className={`sub-menu ${
                    childIndex == 2 || (childIndex == 1 && key == "max" && item.children.length == 2)
                      ? "sub-menu-mt"
                      : ""
                  }`}
                  key={childItem.path}
                >
                  <span title={childItem.hoverTitle}>{childItem.title}</span>
                </div>
              );
            })}
          </div>
          <div className={`right-bg ${item.imgKey}`}></div>
        </div>
      );
    });
  };

  const getMenuList = () => {
    requestPermission(
      () => {
        setMenuInfo();
      },
      (err) => {
        message.error(err.message || "会话过期");
        props.history.push("/sso");
      }
    );
  };

  const setMenuInfo = () => {
    const userInfo = getUserInfo();
    const { permit_app } = userInfo;
    const keys = ["formula", "bi", "max"];
    setUserInfo((state) => {
      state.name = userInfo.name;
      state.avatar = userInfo.avatar;
      keys.forEach((key) => {
        state[`${key}Menus`] = getMenusBykey(key, permit_app);
      });
      state.topMenus = getTopMenus(keys, permit_app);
    });
  };

  useEffect(() => {
    getMenuList();
  }, []);

  return (
    <div className="home-portal">
      <div className="header">
        <div className="left">
          {logoConf.show && logoConf.url && (
            <img style={logoConf.style} src={`${logoConf.url}`} alt="logo" />
          )}
        </div>
        <div className="right">
          <Dropdown trigger="click" overlay={menu} placement="bottom">
            <a className="avatar" href="#!">
              <img src={defaultAvatar} alt="head-img" />
            </a>
          </Dropdown>
        </div>
      </div>
      <div className={`content ${showPlatform ? "platform-content" : ""}`}>
        {showPlatform ? (
          <Platform appKeys={appKeys} />
        ) : (
          <>
            {userInfo.topMenus.length > 0 && (
              <div className="nav-top">
                <header className="title">快速开始</header>
                <ul className="nav-list">
                  {userInfo.topMenus.map((item) => {
                    return (
                      <li key={item.path} onClick={() => goToPage(item)}>
                        <div className="top"></div>
                        <img
                          className="nav-img"
                          src={navImgs[item.imgKey]}
                          alt={item.title}
                        />
                        <span title={item.hoverTitle} className="nav-title">
                          {item.title}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            <div className={`nav-box ${userInfo.topMenus.length == 0 ? 'nav-box-pb' : ''}`}>
              {(userInfo.formulaMenus || []).length > 0 && (
                <div className="left">
                  <header className="title">数据处理</header>
                  {["formula"].map((key) => {
                    return renderMenus(key);
                  })}
                </div>
              )}
              {(userInfo.biMenus || []).concat(userInfo.maxMenus || []).length >
                0 && (
                <div className="right">
                  <header className="title">数据应用</header>
                  {["bi", "max"].map((key) => {
                    return renderMenus(key);
                  })}
                </div>
              )}
              <div className="content-bottom"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Home;
